import singleSpaCss from 'single-spa-css';
import singleSpaVue from 'single-spa-vue';
import { h, createApp } from 'vue';
import './set-public-path';

import App from './App.vue';
import { addCSPMetaTag, removeCSPMetaTag } from './lib/lifecycles';
import Fetching from './plugins/fetching';
import { i18n } from './plugins/i18n';
import Mitt from './plugins/mitt';
import { pinia } from './plugins/pinia';
import { vuetify } from './plugins/vuetify';
import router from './router';

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        translations: this.translations,
        arabicTranslation: this.arabicTranslation,
        sentryUrl: this.sentryUrl,
        sentrySampleRate: this.sentrySampleRate,
        ignoredSentryErrors: this.ignoredSentryErrors,
        checkSentryErrors: this.checkSentryErrors,
        isLocal: this.isLocal,
        showFromforPaymentPage: this.showFromforPaymentPage,
        checkoutSDKLink: this.checkoutSDKLink,
        socketConnection: this.socketConnection,
        sharedMFState: this.sharedMFState,
        payButtonText: this.payButtonText,
        displayRejectButton: this.displayRejectButton,
      });
    },
  },
  handleInstance(app) {
    app.use(router);
    app.use(vuetify);
    app.use(i18n);
    app.use(pinia);
    app.use(Fetching);
    app.use(Mitt);
  },
});

const cssLifecycles = singleSpaCss({
  webpackExtractedCss: process.env.NODE_ENV === 'production',
});

export const bootstrap = [
  addCSPMetaTag,
  vueLifecycles.bootstrap,
  cssLifecycles.bootstrap,
];
export const mount = [cssLifecycles.mount, vueLifecycles.mount];
export const unmount = [
  removeCSPMetaTag,
  vueLifecycles.unmount,
  cssLifecycles.unmount,
];
